<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title">
                <p>需求发布</p>
            </div>
            <a-form :model="formPostDemand" :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                   <div class="con-item">
                        <a-form-item label="服务内容">
                            <a-select @change="onPickService" placeholder="请选择服务内容">
                                <a-select-option :value="sItem.NAME" v-for="(sItem, si) in serviceList" :key="si">{{sItem.NAME}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="期限范围">
                            <a-select @change="onPickDate" placeholder="请选择期限范围">
                                <a-select-option :value="dItem.BIANMA" v-for="(dItem, di) in dateList" :key="di">{{dItem.NAME}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="担保方式">
                            <a-select @change="onPickMethod" placeholder="请选择担保方式">
                                <a-select-option :value="mItem.NAME" v-for="(mItem, mi) in methodList" :key="mi">{{mItem.NAME}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="额度" name="qVal">
                            <a-input v-model:value="qVal" placeholder="请输入额度" style="width: calc(100% - 30px)"></a-input>万元
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="平台推荐">
                            <a-select v-model:value="autoYN" placeholder="请选择平台推荐">
                                <a-select-option value="N">不允许</a-select-option>
                                <a-select-option value="Y">允许</a-select-option>
                            </a-select>
                            <span style="color: red;">需求发布5日后，未有机构主动对接，允许平台匹配并自动对接产品</span>
                        </a-form-item>
                    </div>
                </div>
                <a-modal
                    :visible="modalShow"
                    title="消息提醒"
                    :footer="null"
                    @cancel="modalShow = false"
                >
                    <p>您的需求已发布，请去<a @click="$router.push('/FinancialProducts')">金融产品</a>选择金融服务</p>
                    <div style="margin-top: 20px;border-top: 1px solid #eeeeee;padding-top: 20px;text-align: right;">
                        <a-button @click="$router.push('/')" style="margin-right: 20px;">返回首页</a-button>
                        <a-button @click="onPiPei" type="primary">智能匹配</a-button>
                    </div>
                </a-modal>
                <a-modal
                    :visible="smartProductShow"
                    title="智能匹配-推荐产品"
                    :footer="null"
                    @cancel="smartProductShow = false"
                    width="60%"
                >
                    <div class="product-box">
                        <ul class="product-con" v-if="smarLists.length != 0">
                            <li v-for="(smartItem, smarti) in smarLists" :key="smarti">
                                <div class="item-left">
                                    <img v-if="smartItem.LOGO_ADDR != ''" :src="imgApi + smartItem.LOGO_ADDR">
                                    <img v-else src="../assets/images/prodct-con01.png">
                                </div>
                                <a-divider type="vertical" style="height:auto;margin:0 15px" />
                                <div class="item-right">
                                    <div class="item-right-top">
                                        <p>{{smartItem.PRODUCT_NAME}}</p>
                                        <a-button @click="$router.push({ path: '/ProdutcDetails', query: { id: smartItem.FINANCIALPRODUCTS_ID } })">查看详情</a-button>
                                    </div>
                                    <div class="item-right-bot">
                                        <p>利率区间：<span class="sp-special">{{smartItem.INTEREST_RATE_START}}%-{{smartItem.INTEREST_RATE_END}}%</span></p>
                                        <p>额度范围：<span class="sp-special">{{smartItem.MONEY_START}}万元</span></p>
                                        <p>发行机构：<span>{{smartItem.BANK_NAME}}</span></p>
                                        <p>担保方式：<span>{{smartItem.GUARANTEE_METHOD}}</span></p>
                                        <p>期限范围：<span>{{smartItem.BETWEEN_MONTHS_S}}个月</span></p>
                                        <p>累计申请：<span>{{smartItem.LINK_COUNT}}次</span></p>
                                    </div>

                                </div>
                            </li>
                        </ul>
                        <ul v-else style="text-align: center;">
                            <img src="../assets/images/no-data.png">
                        </ul>
                        <div class="page-box" v-if="smarLists.length != 0">
                            <a-pagination show-quick-jumper v-model:current="page" :total="total" defaultPageSize="2" @change="onPage"/>
                        </div>
                    </div>
                </a-modal>
                <div class="contents">
                    <a-button @click="onSubmit">确认提交</a-button>
                </div>
            </a-form>
        </div>
        <div class="pd-box">
            <div class="title">
                <p>需求历史</p>
            </div>
            <div class="content-history">
                <a-table :columns="columns" :data-source="dList" :pagination="pagination" @change="onDemandPage">
                    <template #resourcePool="{ text: resourcePool }">
                        <a-tag :color="resourcePool === '已发布' ? 'green' : '#999999'">
                            {{ resourcePool }}
                        </a-tag>
                    </template>
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive} from 'vue'
import { mapGetters } from 'vuex'
import Base from '@/api/base.js'
const columns = [
  {
    title: '时间',
    dataIndex: 'time',
    key: 'time',
    width: 120,
    align:'center'
  },
  {
    title: '服务内容',
    dataIndex: 'serviceContent',
    key: 'serviceContent',
    align:'center'
  },
  {
    title: '期限范围',
    dataIndex: 'timeLimit',
    key: 'timeLimit',
    align:'center'
  },
  {
    title: '担保方式',
    dataIndex: 'assureMeans',
    key: 'assureMeans',
    align:'center'
  },
  {
    title: '额度',
    dataIndex: 'limitRange',
    key: 'limitRange',
    align:'center'
  },
  {
    title: '资源池',
    dataIndex: 'resourcePool',
    key: 'resourcePool',
    align:'center',
    slots: {
      customRender: 'resourcePool',
    },
  },
]
export default defineComponent({
    computed: {
        ...mapGetters(['isAuth', 'loginType', 'orgId', 'orgName', 'orgType'])
    },
    setup() {
        //   表单
        const formPostDemand = reactive({
            serviceContent: undefined,
            timeLimit: undefined,
            assureMeans: undefined,
            limitRange: undefined
        })
        return {
            labelCol: {
                span: 6,
            },
            wrapperCol: {
                span: 16,
            },
            formPostDemand,
            columns
        }
    },
    data () {
        return {
            serviceList: [],
            sVal: '',
            dateList: [],
            dVal: '',
            methodList: [],
            mVal: '',
            qVal: '',
            dList: [],
            autoYN: '',
            modalShow: false,
            page: 1,
            total: 0,
            smartProductShow: false,
            smarLists: [],
            imgApi: Base.cs + '/',
            pagination: {},
            dPage: 1,
            pageCount: 10
        }
    },
    methods: {
        onPage (pageNumber) {
            this.page = pageNumber
            this.onPiPei()
        },
        onPickService (e) {
            this.sVal = e
        },
        onPickDate (e) {
            this.dVal = e
        },
        onPickMethod (e) {
            this.mVal = e
        },
        onPiPei () {
            this.smarLists = []
            this.modalShow = false
            this.smartProductShow = true
            this.$store.dispatch('getSmartDemandList', {
                SERVICE_ITEM: this.sVal,
                GUARANTEE_METHOD: this.mVal,
                QUOTA_E: this.qVal,
                VALID_TERM: this.dVal,
                showCount: 2,
                currentPage: this.page,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    if (res.varList.length != 0) {
                        for (let i in res.varList) {
                            if (res.varList[i].LOGO_ADDR !== '') {
                                if (res.varList[i].LOGO_ADDR.split('.')[1] != 'jpg'
                                && res.varList[i].LOGO_ADDR.split('.')[1] != 'png'
                                && res.varList[i].LOGO_ADDR.split('.')[1] != 'jpeg') {
                                    res.varList[i].LOGO_ADDR = ''
                                }
                            }
                        }
                        this.smarLists = res.varList
                        this.total = res.page.totalResult
                    } else {
                        this.smarLists = []
                        this.total = 0
                        this.page = 1
                    }
                } else {
                    this.smarLists = []
                    this.total = 0
                    this.page = 1
                    this.$message.error('服务器异常，获取匹配金融产品列表失败，请稍后重试！')
                }
            })
        },
        onSubmit () {
            let reg = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g
            if (this.sVal === '') {
                this.$message.info('请选择服务内容')
                return false
            } else if (this.dVal === '') {
                this.$message.info('请选择期限范围')
                return false
            } else if (this.mVal === '') {
                this.$message.info('请选择担保方式')
                return false
            } else if (this.qVal === '') {
                this.$message.info('请输入额度')
                return false
            } else if (!this.qVal.match(reg)) {
                this.$message.info('请填写正确的数字')
                return false
			} else if (this.autoYN === '') {
                this.$message.info('请选择平台推荐')
                return false
            }
            let nowTime = new Date().getFullYear().toString() + (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)).toString() + (new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate())
            this.$store.dispatch('addDemand', {
                ENTERPRISE_ID: this.orgId,
                SERVICE_ITEM: this.sVal,
                VALID_TERM: this.dVal,
                GUARANTEE_METHOD: this.mVal,
                QUOTA_E: this.qVal,
                ISSUED_DATE: nowTime,
                tm: new Date().getTime(),
                AUTO_YN: this.autoYN,
                ENTERPRISE_NAME: this.orgName,
                ENTERPRISE_TYPE: this.orgType
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('发布成功！')
                    this.modalShow = true
                    this.getDemandList()
                } else {
                    this.$message.info('系统异常，发布失败！')
                }
            })
        },
        // 获取下拉选项列表
        getSelectList () {
            // 获取服务内容下拉选项列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: '5e3766b8ee284c929c72cbfeba325b3b', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.serviceList = res.list
                }
            })
            // 获取担保方式下拉选项列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: '6c14cf3422324b6aaf7a8637394c9b89', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.methodList = res.list
                }
            })
            // 获取期限范围下拉选项列表
            this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: 'e7486ddcb4c143149deeecbe3ff9a58b', tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.dateList = res.list
                }
            })
        },
        // 获取需求列表
        getDemandList () {
            this.dList = []
            this.$store.dispatch('getDemandList', { showCount: this.pageCount, currentPage: this.dPage, ENTERPRISE_ID: this.orgId }).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        for (let i in datas) {
                            this.dList.push({
                                key: parseInt(i) + 1,
                                time: datas[i].ISSUED_DATE,
                                serviceContent: datas[i].SERVICE_ITEM,
                                timeLimit: datas[i].VALID_TERM + '个月以下',
                                assureMeans: datas[i].GUARANTEE_METHOD,
                                limitRange: datas[i].QUOTA_E + '万',
                                resourcePool: '已发布'
                            })
                        }
                        this.pagination.total = res.page.totalResult
                    } else {
                        this.pagination.total = 0
                        this.dList = []
                    }
                } else {
                    this.dList = []
                    this.pagination.total = 0
                    this.$message.error('服务器异常，获取需求列表失败，请稍后重试！')
                }
            })
        },
        onDemandPage (pagination) {
            this.dPage = pagination.current
            this.getDemandList()
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        // 获取下拉选项列表
        this.getSelectList()
        // 获取需求列表
        this.getDemandList()
    }
})
</script>
<style lang="less" scoped>
.content-history ::v-deep(.ant-table-thead){
    background:@color-blue; /* 标准语法 */
}
.content-history ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}

</style>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }

        .contents{
            text-align: center;
            .ant-btn{
                padding: 0 120px;
                height: 40px;
                color: @color-ff;
                background:@color-blue; /* 标准语法 */
            }
        }
        .content-history{
            margin-top: 20px;
            font-size: 16px;
        }
    }
    .pd-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background:@color-blue; /* 标准语法 */
    }
}

.product-box{
    .product-con{
        padding: 20px;
        background-color: @color-ff;
        border-radius: 5px;
        li{
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: @border-base;
            .item-left{
                width: 384px;
                img{
                    width: 100%;
                    height: 215px;
                }
            }
            .item-right{
                width: 880px;
                .item-right-top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 10px;
                    p{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 0;
                        font-size: 48px;
                        font-weight: bold;
                        img{
                            margin-left: 20px;
                            width: 150px;
                            height: 40px;
                        }
                    }
                }
                .item-right-bot{
                    display: flex;
                    justify-content: start;
                    flex-wrap: wrap;
                    border-top: @border-ea;
                    padding-top: 10px;
                    p{
                        width: 290px;
                        padding:5px;
                        margin-bottom: 0;
                        font-size: @font-lg;
                        line-height: 30px;
                        .sp-special{
                            color: @color-blue;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        li:first-child{
            padding-top: 0;
        }
    }
    .page-box{
        padding: 15px 20px;
        background-color: @color-ff;
        border-radius: 5px;
        margin-bottom: 30px;
        position: relative;
        .ant-pagination{
            display: flex;
            justify-content: center;

        }
    }
}
</style>
